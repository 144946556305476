body {
  font-family: Arial, sans-serif;
}

.button-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

#navMenu {
  position: absolute;
  top: calc(100% + 10px); /* 10px below the burger button */
  left: 0;
}

.position-relative {
  position: relative;
}

.text-studio {
  position: absolute;
  top: 10px;
  left: 5%;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.text-work-hard {
  position: absolute;
  top: 40%; /* Adjust this value to match the desired position */
  left: 10%; /* Adjust this value to match the desired position */
  color: white;
  font-size: 1rem;
  font-weight: bold;
}
